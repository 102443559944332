/** Author: Draco Chan < draco.chan@butterfly.com.au / im@dracochan.com >
 * 
 * Version: 0.3
 * Known issue: None
 * Requirement: jQuery 1.8+
 * Usage:
jQuery(document).ready(function() {
	jQuery('.items').alignHeight('.item', '.readmore');
});
 * Parameters:
itemSelector: jQuery selector string of target items of parent
bottomObjectSelector: jQuery selector string of the object that should stay at the bottom of this item
bottomPadding: space in pixel
rangeMin (optional): The script will be disabled if window is smaller than this.
rangeMax (optional): The script will be disabled if window is larger than this.
 **/
 
jQuery.fn.alignHeight = function(itemSelector, bottomObjectSelector, bottomPadding, rangeMin, rangeMax){
	return this.each(function() { 
		var el = jQuery(this);
		if( itemSelector===undefined ){
			return true;
		}
		if( bottomObjectSelector===undefined ){
			bottomObjectSelector = false;
		}
		if( bottomPadding===undefined || bottomPadding===false ){
			var bottom = el.find(itemSelector).css('padding-bottom');
			if(bottom!='') {
				bottomPadding = bottom;
			} else {
				bottomPadding = 0;
			}
		}
		
		var includeOuter = (el.css('box-sizing').toLowerCase()=='border-box');

		var aligned = false;
		var align = function () {
			var width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
			if((rangeMin!==undefined && width<rangeMin) || (rangeMax!==undefined && width>rangeMax)) {
				if(aligned) {
					aligned = false;
					el.find(itemSelector).each(function(){
						var jItem = jQuery(this);
						jItem.css('height', '');

						var jBO = (bottomObjectSelector==false)? false : jItem.find(bottomObjectSelector);
						if (jBO!=false && jBO.length!=0) {
							jBO.css('position', '');
							jBO.css('bottom', '');
						}
					});
				}
				return true;
			}

			var lastY = 0;
			var lastH = 0;
			var jItems = new Array();
			el.find(itemSelector).each(function(){
				var jItem = jQuery(this);
				if(jItem.css('display')=='table-cell')
					return true;

				var jBO = (bottomObjectSelector==false)? false : jItem.find(bottomObjectSelector);
				if (jBO!=false && jBO.length!=0) {
					jBO.removeAttr('style');
				}
				var currentY = jItem.position().top;
				jItem.css('height', 'auto'); /*reset*/
				
				var height = (includeOuter?jItem.outerHeight():jItem.height()); 
				if(Math.abs(currentY-lastY)>5) { /*allow small gap*/
					jItems = new Array();
					lastH = height;
					lastY = currentY;
				} else if(lastH<height) {
					lastH = height;
					jQuery.each(jItems, function(){
						jQuery(this).css('height', lastH+'px'); 
					});
				}

				jItem.css('height', lastH+'px'); 
				jItems.push(jItem);
				if (jBO!=false && jBO.length!=0) {
					jItem.css('position', 'relative');
					jBO.css('position', 'absolute');
					jBO.css('bottom', bottomPadding);
				}
				aligned = true;
			});
		};

		/* Re-adjust height after images loaded */
		var loadingTimeout = null;
		var setAlign = function() {
			if(loadingTimeout!==null)
				clearTimeout(loadingTimeout);
			loadingTimeout = setTimeout(function(){
				align();
			}, 50);
		};
		el.find(itemSelector).each(function(){
			var images = jQuery(this).find('img');
			images.one("load", setAlign);
			images.each(function() {
				if(this.complete)
					setAlign();
			});
		});

		jQuery(window).resize(function(){
			setTimeout(align, 50); /*avoid buggy browser maximise button*/
		});

		align();
	});
};